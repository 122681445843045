import { LDFlagSet } from 'launchdarkly-js-sdk-common';

import { Schemas } from 'src/visualization/constants';
import {
    SUPPORTED_SCHEMAS,
    DRIVER_SUPPORTED_SCHEMAS,
} from 'src/visualization/utils/supportedTypes';

export const extractSchema = (fullSchema: string) => {
    if (!fullSchema) {
        return '' as Schemas;
    }

    return fullSchema.substring(fullSchema.lastIndexOf('/') + 1).toLowerCase() as Schemas;
};

export function isSchemaViewable(fullSchema: string, flags: LDFlagSet) {
    const schema = extractSchema(fullSchema);
    const { evouiTriangleMeshRendering, evouiRegularGridRendering } = flags;
    if (evouiRegularGridRendering) {
        SUPPORTED_SCHEMAS.push(Schemas.Regular2DGridSchema);
    }
    if (evouiTriangleMeshRendering) {
        SUPPORTED_SCHEMAS.push(Schemas.TriangleMeshSchema);
    }
    return SUPPORTED_SCHEMAS.includes(schema);
}

export function isSchemaDriverViewable(fullSchema: string) {
    const schema = extractSchema(fullSchema);
    return DRIVER_SUPPORTED_SCHEMAS.includes(schema);
}
